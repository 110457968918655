// This is going to export named layouts that will be rendered by various dashboard grid screens

// The layout for the AdvancedAnalytics screen
export const getAnalyticsLayout = (analytics) => {
  return {
    lg: [
      {
        i: "LineChart",
        x: 0,
        y: 0,
        w: 7,
        h: 4.5,
        minW: 7,
        minH: 4.5,
      },
      {
        i: "StarRatingDist",
        x: 7,
        y: 6,
        w: 5,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentDist",
        x: 7,
        y: 4,
        w: 5,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentVariant",
        x: 0,
        y: 4,
        w: 7,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "SentCategory",
        x: 0,
        y: 6,
        w: 7,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "BestRev",
        x: 7,
        y: 0,
        w: 5,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
      {
        i: "WorstRev",
        x: 7,
        y: 2,
        w: 5,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
    ],
    md: [
      {
        i: "LineChart",
        x: 0,
        y: 0,
        w: 6,
        h: 4.5,
        minW: 7,
        minH: 4.5,
      },
      {
        i: "StarRatingDist",
        x: 7,
        y: 6,
        w: 4,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentDist",
        x: 7,
        y: 4,
        w: 4,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentVariant",
        x: 0,
        y: 4,
        w: 6,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "SentCategory",
        x: 0,
        y: 6,
        w: 6,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "BestRev",
        x: 7,
        y: 0,
        w: 4,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
      {
        i: "WorstRev",
        x: 7,
        y: 2,
        w: 4,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
    ],
    sm: [
      {
        i: "LineChart",
        x: 0,
        y: 0,
        w: 6,
        h: 4.5,
        minW: 7,
        minH: 4.5,
      },
      {
        i: "StarRatingDist",
        x: 3,
        y: 7.75,
        w: 3,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentDist",
        x: 0,
        y: 7.75,
        w: 3,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentVariant",
        x: 0,
        y: 4,
        w: 3,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "SentCategory",
        x: 3,
        y: 4,
        w: 3,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "BestRev",
        x: 7,
        y: 0,
        w: 3,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
      {
        i: "WorstRev",
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
    ],
    xs: [
      {
        i: "LineChart",
        x: 0,
        y: 0,
        w: 6,
        h: 4.5,
        minW: 7,
        minH: 4.5,
      },
      {
        i: "StarRatingDist",
        x: 3,
        y: 7.75,
        w: 2,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentDist",
        x: 0,
        y: 7.75,
        w: 2,
        h: 3,
        minH: 2.5,
        minW: 4,
      },
      {
        i: "SentVariant",
        x: 0,
        y: 4,
        w: 2,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "SentCategory",
        x: 3,
        y: 4,
        w: 2,
        h: 3.75,
        minH: 3.75,
        minW: 5,
      },
      {
        i: "BestRev",
        x: 7,
        y: 0,
        w: 2,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
      {
        i: "WorstRev",
        x: 0,
        y: 2,
        w: 2,
        h: 2,
        minH: 2,
        minW: 4.5,
      },
    ],
  };
};
